import React from "react";
import styled from "styled-components";

// Utils
import { rem, Font, Color, responsive } from "../../utils/style";

// Components
import LoginForm from "./LoginForm";
import MagicLink from "../MagicLink";
import Text from "../Text";

// Styled Elements
const Wrapper = styled.div.attrs({
  className: "d-flex flex-column",
})``;

const LoginHeading = styled.div`
  margin-bottom: 32px;

  ${responsive.md`
    margin-bottom: 40px;
  `}

  h1 {
    margin: 0;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 8px;

    font-size: ${rem(18)};
    line-height: ${rem(28)};

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    `}
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    letter-spacing: 0px;
    line-height: ${rem(26)};
    font-weight: normal;
    margin-bottom: 0;

    ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
  }
`;

const PasswordLink = styled(MagicLink)`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  align-self: center;
  border-bottom: 2px solid ${Color.ritualBlue};
  transition: opacity 0.2s;

  margin-top: 16px;
  margin-bottom: 24px;

  ${responsive.md`
    margin-top: 24px;
    margin-bottom: 56px;
  `}

  &:hover {
    opacity: 0.56;
  }
`;

const CreateAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  letter-spacing: 0px;
  text-align: center;

  p {
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};

      // displays on single line for desktop
      br {
        display: none;
      }
      a {
        margin-left: 5px;
      }
    `}
  }
`;

const CreateAccountLink = styled(MagicLink)`
  border-bottom: 1px solid ${Color.ritualBlue};
  line-height: 20px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.56;
  }
`;

export default class LoginElements extends React.Component {
  render() {
    const accountUrl =
      process.env.GATSBY_ACCOUNT_URL || "https://account.ritual.com";
    return (
      <Wrapper>
        <LoginHeading>
          <h1>
            <Text id="authenticate.login.heading" defaultMessage="Log In" />
          </h1>
          <p>
            <Text
              id="authenticate.login.heading-p"
              defaultMessage="We’ll pick up right where we left off."
            />
          </p>
        </LoginHeading>

        <LoginForm />

        <PasswordLink target="_self" href={`${accountUrl}/forgot-password`}>
          <Text
            id="authenticate.login.forgot-password"
            defaultMessage="Forgot your password?"
          />
        </PasswordLink>

        <CreateAccount>
          <p>
            <Text
              id="authenticate.login.new-to-ritual"
              defaultMessage="New to Ritual?"
            />
            <br />
            <CreateAccountLink to="/signup">
              <Text
                id="authenticate.login.create-account"
                defaultMessage="Create an account"
              />
            </CreateAccountLink>
            .
          </p>
        </CreateAccount>
      </Wrapper>
    );
  }
}
