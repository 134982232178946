import React from "react";
import { graphql } from "gatsby";

// Components
import LoadingWrapper from "../../components/global/LoadingWrapper";
import PageSEO from "../../components/seo/Page";
import PageHero from "../../components/PageHero";
import AuthenticateContainer from "../../components/authenticate/AuthenticateContainer";
import LoginElements from "../../components/authenticate/LoginElements";
import BarrierBusters from "../../components/authenticate/BarrierBusters";

// Redux
import { connect } from "react-redux";
import appSelectors from "../../store/app/selectors";
import userSelectors from "../../store/user/selectors";

export const LoginPage = class LoginPageTemplate extends React.Component {
  componentDidMount() {
    this.props.updatePageData({
      label: "Log-in",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: true,
    });
  }

  render() {
    const seo = {
      pagePath: "/login",
      title: "Login",
      description: "Login",
    };

    const { heroImageMobile, heroImageDesktop } = this.props.data;

    return (
      <>
        <PageSEO {...seo} noIndex={true} />

        <LoadingWrapper
          isLoading={!this.props.isUserLoaded}
          shouldRedirectOnLoad={this.props.isLoggedIn}
        >
          <PageHero
            imageMobile={heroImageMobile}
            imageDesktop={heroImageDesktop}
            imageObjectPosition="50% 100%"
            className="height-fit full-width-mobile"
          >
            <AuthenticateContainer>
              <LoginElements />
            </AuthenticateContainer>
          </PageHero>

          <BarrierBusters />
        </LoadingWrapper>
      </>
    );
  }
};

const mapStateToProps = state => {
  return {
    isUserLoaded: appSelectors.isUserLoaded(state),
    isLoggedIn: userSelectors.isLoggedIn(state),
  };
};

export default connect(mapStateToProps, {})(LoginPage);

export const pageQuery = graphql`
  query LoginQuery($locale: String!) {
    heroImageMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "7Dathy6nXb81LmIIv8mXZA" }
    ) {
      description
      id
      fluid(maxWidth: 912, quality: 80) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroImageDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "4huChKZwlYOBIDx5pCVboj" }
    ) {
      description
      id
      fluid(maxWidth: 1920, quality: 80) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
